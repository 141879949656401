<template>
  <base-modal-view v-show="loaded" title="What's new in iAppraise?" :wide="true">
    <div class="flex-column">
      <div class="image-container">
        <img @load="onLoad()" src="/assets/img/splash2.jpg" alt="Tender Splash" />
        <div class="main-text">
          <p class="title">Remarketing</p>
          <p class="subtitle">The best way to follow up your leads</p>
        </div>
        <p class="trail">See what iAppraise Remarketing can do for you</p>
      </div>
      <div class="text-container">
        <div class="flex-column">
          <div class="flex-row">
            <img class="hook-image" src="/assets/img/icon-car.png" alt="car" />
            <p class="hook-title">The Problem</p>
          </div>
          <ul class="hook-text">
            <li>Dealership appraises 100 cars</li>
            <li>On average 70 of these cars are not traded in the dealership</li>
            <li>The dealership is not effectively remarketing to these missed opportunities to buy more used cars</li>
            <li>Dealerships want more used car buying opportunities</li>
          </ul>
        </div>
        <div class="flex-column">
          <div class="flex-row">
            <img class="hook-image" src="/assets/img/icon-dollar.png" alt="car" />
            <p class="hook-title">iAppraise Solution</p>
          </div>
          <ul class="hook-text">
            <li>
              Via a proven algorithm and target SMS campaign our award-winning technology targets these missed
              opportunities and delivers the dealership quantified leads of people who are wanting to sell their car and
              would like to discuss further.
            </li>
            <li>
              Dealership receives pre-qualified SMS, email & push notifications of people who still wish to sell their
              used car.
            </li>
          </ul>
        </div>
        <div class="flex-column">
          <div class="flex-row">
            <img class="hook-image" src="/assets/img/icon-star.svg" alt="car" />
            <p class="hook-title">Key Points</p>
          </div>
          <ul class="hook-text">
            <li>On average 70 % of cars go untraded</li>
            <li>On average 10 % of targeted marketing leads result in buying opportunities</li>
            <li>Customer is targeted 14 days post dealership visit</li>
            <li>Used vehicle acquisition cost to dealer is circa $150</li>
          </ul>
        </div>
      </div>
      <div class="contact-container flex-column">
        <p class="contact-text">See what iTender can do for you</p>
        <primary-button :title="'Talk to our sales team'" v-on:click="goToContact()" />
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";

export default {
  name: "AdvertisingRemarketingModal",
  components: { BaseModalView, PrimaryButton },
  data: function () {
    return {
      loaded: false
    };
  },
  methods: {
    onLoad: function () {
      this.loaded = true;
    },
    goToContact: function () {
      this.$router.push({ name: "ContactUs" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.image-container {
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 45vh;
  }

  .main-text {
    position: absolute;
    top: 10%;
    left: 24px;
    width: 25%;

    .title {
      @extend .h3-title;
      color: white;
    }

    .subtitle {
      @extend .h3-subtitle;
      color: white;
    }
  }

  .trail {
    @extend .h3-subtitle;

    position: absolute;
    bottom: 20%;
    right: 24px;
    max-width: 25%;
    color: white;
  }
}

.text-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  margin-top: 24px;
}

.hook-text {
  @extend .body-1;
  color: #262626;
}

.hook-image {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.contact-container {
  margin: auto;
  margin-top: 24px;

  .contact-text {
    @extend .body-2;
    @extend .margin-bottom-05;
    text-align: center;
  }
}

.no-underline {
  text-decoration: none;
  margin: auto;
}
</style>
