<template>
  <div>
    <div id="account-page">
      <h1 class="h1-title">Account</h1>
      <div>
        <div v-if="currentReseller && currentReseller.requires_payments">
          <stripe-checkout v-if="publishableKey" ref="checkoutRef" :pk="publishableKey" :session-id="sessionId" />
          <primary-button
            class="submit-button"
            :title="loading ? 'Loading...' : 'Subscribe'"
            @click="checkoutSubmit"
            :disabled="loading"
            v-if="currentReseller && !currentReseller.subscription_id"
          />
          <primary-button
            class="submit-button"
            :title="billingLoading ? 'Loading...' : 'Manage Billing'"
            @click="manageBilling"
            :disabled="billingLoading"
            v-if="currentReseller && currentReseller.subscription_id"
          />
        </div>
        <p class="margin-top-2 body-2-bold">Account e-mail</p>
        <p class="body-2 margin-bottom-025">
          {{ currentDealership ? currentDealership.email : currentReseller.email }}
        </p>
        <p class="body-2-bold margin-top-05">Account Status</p>
        <h2 class="body-2 margin-bottom-025">Active</h2>
        <div class="flex-row margin-top">
          <div>
            <p class="text-field-title margin-bottom-025">
              {{ currentReseller ? "First Name" : "Dealership Name" }}
            </p>
            <input type="text" class="text-field" v-model="first_name" />
          </div>
          <div class="margin-left-05" v-if="currentReseller">
            <p class="text-field-title margin-bottom-025">Last Name</p>
            <input type="text" class="text-field" v-model="last_name" />
          </div>
          <primary-button class="margin-top-auto margin-left-05" title="Update" v-on:click="updateDetails()" />
        </div>
      </div>
      <div v-if="currentDealership" class="flex-column margin-top">
        <div class="width-1000 margin-left-auto margin-right-auto">
          <h2 class="h2-title">Current Permissions</h2>
          <table class="results-table">
            <tr class="header">
              <th>Permission</th>
              <th>Enabled</th>
            </tr>
            <tr class="data-row selectable" v-on:click="showRegoModal = true">
              <td>Can Lookup by Vehicle Registration</td>
              <td>{{ yesNoString(currentDealership.can_rego_authorise) }}</td>
            </tr>
            <tr class="data-row selectable" v-on:click="showMandatoryModal = true">
              <td>Forces Mandatory Input on Extra Fields</td>
              <td>{{ yesNoString(currentDealership.force_mandatory_fields_on_form_input) }}</td>
            </tr>
            <tr class="data-row selectable" v-on:click="showReportingModal = true">
              <td>Has Access to Reporting</td>
              <td>{{ yesNoString(currentDealership.has_reporting_permissions) }}</td>
            </tr>
            <tr class="data-row selectable" v-on:click="showAutograbModal = true">
              <td>Has Access to Autograb</td>
              <td>{{ yesNoString(currentDealership.is_autograb_account) }}</td>
            </tr>
            <tr class="data-row selectable" v-on:click="showAutograbModal = true">
              <td>Has Access to Offsite Appraisal Requests</td>
              <td>{{ yesNoString(currentDealership.has_offsite_access) }}</td>
            </tr>
            <tr class="data-row selectable" v-on:click="showRemarketingModal = true">
              <td>Has Access to Manual Remarketing</td>
              <td>
                {{
                  yesNoString(
                    (currentDealership.is_marketing_account && currentDealer.is_manager) ||
                      currentDealer.has_marketing_access
                  )
                }}
              </td>
            </tr>
            <tr class="data-row selectable" v-on:click="showRemarketingModal = true">
              <td>Will do Automatic Remarketing</td>
              <td>{{ yesNoString(currentDealership.is_automated_marketing_account) }}</td>
            </tr>
            <tr class="data-row selectable" v-on:click="showTenderModal = true">
              <td>Has Access to Tendering</td>
              <td>{{ yesNoString(currentDealership.is_tender_account) }}</td>
            </tr>
            <tr class="data-row selectable" v-on:click="showPPSRModal = true">
              <td>Has Access to PPSR</td>
              <td>{{ yesNoString(currentDealership.ppsr_permission) }}</td>
            </tr>
            <tr class="data-row selectable" v-on:click="showSalesEditModal = true">
              <td>Sales Users can Edit From Website</td>
              <td>{{ yesNoString(currentDealership.sales_users_can_edit_from_website) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <advertising-autograb-modal v-if="showAutograbModal" @cancel="showAutograbModal = false" />
      <advertising-tender-modal v-if="showTenderModal" @cancel="showTenderModal = false" />
      <advertising-remarketing-modal v-if="showRemarketingModal" @cancel="showRemarketingModal = false" />
      <advertising-custom-modal
        v-if="showRegoModal"
        title="Lookup by Vehicle Registration"
        subtitle="Maintain Accuracy, Reduce Errors"
        description="Looking up vehicles by their registration can provide more accurate information than manual entry, and a lot faster! Reduce errors and speed up your appraisals with iAppraise Rego Lookups."
        @cancel="showRegoModal = false"
      />
      <advertising-custom-modal
        v-if="showMandatoryModal"
        title="Set Extra Mandatory Fields"
        subtitle="Ensure the Best Data"
        description="Ensure your staff provide the best information so that you can get the best leads. By setting extra mandatory fields, you can be confident that you'll get the most informed and accurate offers. "
        @cancel="showMandatoryModal = false"
      />
      <advertising-custom-modal
        v-if="showReportingModal"
        title="Monitor your Dealership's Progress"
        subtitle="Watch, React, Grow"
        description="Advanced reporting gives you deep dives into your data and allows you to monitor your best performers."
        @cancel="showReportingModal = false"
      />
      <advertising-custom-modal
        v-if="showPPSRModal"
        title="Order PPSRs in One Click!"
        subtitle="Encumbered? Written Off? Stolen?"
        description="Order PPSRs off your phone in a single click. You'll be able to see if a vehicle is written off, stolen or encumbered within 90 seconds, and you get the certificate. You'll also be able to do independent PPSRs from the desktop at any point in time, just by looking it up by its VIN."
        @cancel="showPPSRModal = false"
      />
      <advertising-custom-modal
        v-if="showSalesEditModal"
        title="Sales User Updates"
        subtitle="Any Staff, All Access"
        description="Allow Sales Users to modify appraisals from their desktop so you can have more accurate data at their fingertips. Or limit their access so that you can be sure that it will always be as you put it in."
        @cancel="showSalesEditModal = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { paymentCheckout, manageBilling } from "@/api/Reseller.js";

import PrimaryButton from "../../components/Buttons/v2/PrimaryButton.vue";
import AdvertisingAutograbModal from "../../components/Modals/v2/AdvertisingAutograbModal.vue";
import AdvertisingTenderModal from "../../components/Modals/v2/AdvertisingTenderModal.vue";
import AdvertisingRemarketingModal from "../../components/Modals/v2/AdvertisingRemarketingModal.vue";
import AdvertisingCustomModal from "../../components/Modals/v2/AdvertisingCustomModal.vue";

export default {
  components: {
    PrimaryButton,
    StripeCheckout,
    AdvertisingAutograbModal,
    AdvertisingTenderModal,
    AdvertisingCustomModal,
    AdvertisingRemarketingModal
  },
  name: "Account",
  data: function () {
    return {
      first_name: "",
      last_name: "",
      loading: false,
      billingLoading: false,
      publishableKey: "",
      sessionId: "", // session id from backend
      showAutograbModal: false,
      showTenderModal: false,
      showRegoModal: false,
      showMandatoryModal: false,
      showReportingModal: false,
      showPPSRModal: false,
      showSalesEditModal: false,
      showRemarketingModal: false
    };
  },
  methods: {
    updateDetails: function () {
      if (this.currentDealership) {
        this.updateDealership(this.first_name);
      } else {
        this.updateReseller({
          reseller: this.currentReseller,
          resellerDict: {
            first_name: this.first_name,
            last_name: this.last_name
          }
        });
      }
    },
    checkoutSubmit() {
      // Get data
      // Set loading to true
      this.loading = true;
      paymentCheckout()
        .then(response => {
          // Set data variable
          let data = response?.data;
          // Set publishable key
          this.publishableKey = data?.stripe_key;
          // Set session id
          this.sessionId = data?.session_id;
          // Wait for next tick to update variable
          this.$nextTick(() => {
            // Redirect to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout();
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
      this.loading = true;
    },
    manageBilling() {
      // Get data
      // Set loading to true
      this.billingLoading = true;
      manageBilling()
        .then(response => {
          // Set data variable
          let data = response?.data;
          // Set manage billing url
          let url = data?.url;
          // Open url
          window.open(url, "_blank");
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.billingLoading = false;
        });
      this.billingLoading = true;
    },
    yesNoString: function (boolean) {
      return boolean ? "Yes" : "No";
    },

    ...mapActions({
      updateDealership: "dealershipStore/updateDealership",
      updateReseller: "resellerStore/updateReseller",
      fetchCurrentDealership: "dealershipStore/fetchCurrentDealership"
    })
  },
  computed: {
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership,
      currentReseller: state => state.resellerStore.currentReseller
    })
  },
  mounted: function () {
    this.first_name = this.currentDealership ? this.currentDealership.first_name : this.currentReseller.first_name;
    this.last_name = this.currentDealership ? this.currentDealership.last_name : this.currentReseller.last_name;
    if (this.currentDealership) {
      this.fetchCurrentDealership();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#account-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
